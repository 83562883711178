.card-produto-classe .ui-col-element {
    border-radius: 35px !important;
}

.card-produto-classe .ui-el-item-inner{
    
    text-align: center;
    font-weight: bold;
    font-size: 17px;
    margin: 10px 0;

}

.card-produto-classe .ui-el-item-comprar .ui-el-item-inner{

    display: flex !important;
    justify-content: center;

}

.card-produto-classe .ui-el-item-comprar button{
    font-weight: 100;
    color: #fc3131;
    width: 50%;
    border: 0;
    border-radius: 80px !important;
    height: 40px !important;
    background: #eee;
    font-size: 15px;
}

.card-produto-classe .ui-el-item-comprar button span{
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 500;
}

.card-produto-classe .ui-el-item-comprar button:hover{
    background: #fc3131 !important;
}

.card-produto-classe .ui-button-icon.ui-button-label i {
    margin-right: 0px;
    margin-top: -5px;
}

.card-produto-classe .ui-el-item-valor, .card-produto-classe .ui-el-item-indisponivel{
    margin: -25px 0 !important;
    color: #fc0101;
}

.favorito-classe{
    z-index: 10;
    position: absolute;
    left: 3px;
    top: 0px;
}

.favorito-classe button {
    min-width: 48px !important;
    padding: 22px 0 !important;
    box-shadow: 0 0 0 transparent !important;
    font-size: 25px;
    border-radius: 50px !important;
    border-color:  #fc0101 !important;
}

.favorito-classe i{
    color: #fc0101;
}


.card-produto-classe > .ui-col-element{
    height: 410px !important;
}

.card-produto-classe .ui-tag{
    background: transparent;
}

.card-produto-classe .ui-el-item-destaque .ui-el-item-inner {
    right: 10px;
    left: auto;
    top: -10px;
}

.cart-label-classe{
    font-size: 15px;
    font-weight: bold;
}

.ui-classe-font-produto{
    font-family: 'Montserrat', sans-serif !important;
}


.ui-valor-original {
    margin: -10% 0 5% 0;
}