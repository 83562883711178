@media (max-width: 990px) {
  .ui-catalogo-top > div {
    display: block;
    width: 100%;
    border-bottom: 1px solid #dedede;
  }
  .ui-search-feedback,
  .ui-grid-controls .form-control,
  .ui-search-feedback-rows {
    display: none !important;
  }
  .ui-catalogo-top > div:last-child {
    border-bottom: 0;
  }
  .ui-search-top-controls {
    padding-top: 15px;
  }
  .ui-el-type-crud .ui-buttons-left {
    display: inline-flex;
  }
  .product-detail {
    margin: 10px;
  }
  .product-detail .ui-el-item-right {
    margin-top: 30px;
  }
  .ui-grid-row.ui-card-produto {
    width: 50% !important;
    margin: 0 !important;
    display: inline-flex !important;
  }
  .ui-grid-row.ui-card-produto .ui-col-element {
    width: calc(100% - 10px);
    margin: 5px;
  }
  .ui-catalogo-data,
  .ui-carousel-label {
    margin-right: 0;
    margin-left: 0;
  }
  .ui-bloco-entrega {
    padding-bottom: 30px;
    padding-right: 0 !important;
  }
  .ui-timeline .ui-col {
    width: 100%;
    display: block;
  }
  .ui-timeline .ui-tl-icon {
    height: 30px;
    top: 35px;
    z-index: 1;
  }
  .ui-timeline .ui-tl-bar {
    top: 0;
    height: auto;
    width: 6px;
    left: calc(50% - 5px);
    bottom: 0;
  }
  .ui-timeline .ui-tl-date {
    background: #ffff;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 3;
  }
  .ui-tl-label {
    position: absolute;
    z-index: 999;
    background: #fff;
  }
  .ui-col-1,
  .ui-col-2,
  .ui-col-3,
  .ui-col-4,
  .ui-col-5,
  .ui-col-6,
  .ui-col-7,
  .ui-col-8,
  .ui-col-9,
  .ui-col-10,
  .ui-col-11,
  .ui-col-12 {
    width: 100%;
    display: inline-block !important;
  }
  .ui-el-type-card {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .ui-view {
    margin: 20px 15px;
  }
  .ui-grid-col {
    display: contents;
    padding-bottom: 5px;
    border-bottom: none;
    width: 50%;
  }

  .ui-view-informativos .ui-grid-col {
    display: inherit !important;
    width: 100% !important;
  }

  .ui-view-informativos .ui-el-type-list .ui-col-detalhes {
    max-width: 100%;
  }

  .ui-grid-row {
    box-shadow: 0px 0px 5px 0px #0000001f;
    position: relative;
    display: block;
    padding: 10px;
  }

  .ui-grid-row.ui-grid-col-row.ui-col.ui-row-odd.ui-click {
    border-bottom: 0 !important;
  }
  .ui-grid-row.ui-grid-col-row.ui-col.ui-row-even.ui-click {
    border-bottom: 0 !important;
  }

  .ui-row-card.ui-card-produto.ui-grid-row.ui-grid-col-row.ui-col.ui-row-even {
    border-bottom: 0 !important;
  }

  .ui-fluxo-row.ui-fluxo-row-ANDAMENTO,
  .ui-fluxo-row.ui-fluxo-row-REENVIADO,
  .ui-fluxo-row.ui-fluxo-row-APROVADO,
  .ui-fluxo-row.ui-fluxo-row-FINALIZADO,
  .ui-fluxo-row.ui-fluxo-row-CORRECOES,
  .ui-fluxo-row.ui-fluxo-row-ATIVO {
    border-bottom: 0 !important;
  }

  .ui-row-header {
    display: none !important;
  }
  .ui-col-remove {
    position: absolute;
    right: 0;
    top: 2px;
    float: right;
  }
  .ui-col-label-mobile {
    display: block;
  }
  .ui-content {
    padding: 0 !important;
    margin: 0 20px !important;
  }
  .menu-left {
    display: none;
    width: calc(100% - 100px);
    z-index: 9999;
  }
  .menu-left.menu-show {
    display: inherit;
  }
  .view-data {
    overflow-x: hidden;
    overflow-y: auto;
    margin-left: 0;
  }
  .navbar.fixed-top {
    left: 0;
  }
  .fixed-top {
    z-index: 9999 !important;
  }
  .navbar-collapse.show {
    display: inline-flex;
    height: 95px;
    transition: 0.1s;
  }

  .navbar-menu-p-right {
    display: contents;
  }

  .navbar-collapse.collapsing {
    display: inline-flex;
    transition: 0s !important;
  }

  table,
  tr,
  td {
    display: block;
    text-align: center;
  }
  td {
    padding: 10px;
  }
  .ui-promocao-data > div {
    display: block;
    width: 100% !important;
    height: auto;
  }
  .ui-promocao-data {
    display: block;
  }
  .ui-promo-bloco.ui-promo-plus {
    width: 100%;
  }
  .ui-card-produto .ui-el-avatar {
    height: auto;
    width: 70px;
    padding-top: 30px;
  }
  /*.ui-card-produto, .ui-card-produto * {
    font-size: 16px;
  }*/

  .ui-card-produto .ui-el-item-indisponivel {
    font-size: 15px;
  }

  .ui-el-item-descricao {
    font-size: 15px;
  }

  div#corpo_descricao_2 {
    max-height: 121px;
  }

  .ui-row-card .ui-col-element {
    height: 400px;
  }

  .ui-card-produto .ui-el-item-comandos button,
  .ui-card-produto .ui-el-item-comandos button * {
    font-size: 12px !important;
  }
  .ui-el-item-itens_search .ui-el-item-ir_catalogo {
    position: relative;
    width: 100%;
  }
  .ui-el-item-itens_search .ui-el-item-ir_catalogo button {
    width: 100%;
    margin-top: 10px;
  }
  .ui-pagamento .ui-el-type-complete .ui-el-item-inner {
    /* width: 265px; */
    margin-bottom: 10px;
  }
  .ui-layout-table .ui-search-data {
    overflow: hidden;
  }
  .menu-lateral .ui-grid-col {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
  }
  .menu-lateral .ui-grid,
  .menu-lateral .ui-grid-row {
    width: 100% !important;
    text-align: left;
    display: table;
  }
  .menu-lateral .ui-grid-row .ui-col-type-icon {
    width: 30px !important;
  }
  .menu-lateral .ui-grid-row .ui-col-label {
    width: calc(100% - 30px);
  }
  .menu-lateral .ui-col-icon {
    width: 55px;
    padding: 15px;
    padding-left: 20px;
  }
  .ui-catalogo-carousel .ui-layout-carousel {
    margin-left: 10px;
    margin-right: 10px;
  }
  .ui-catalogo-carousel .ui-layout-carousel .ui-row-card .ui-col-element {
    margin: 5px;
  }

  .ui-layout-carousel .ui-grid {
    height: auto !important;
  }

  .ui-layout-inline > .ui-el-item-inner > .ui-el-item {
    display: block;
    width: 90%;
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-colorInherit {
    width: 100%;
  }

  .ui-catalogo-acts {
    display: none;
  }

  .ui-catalogo-nav {
    display: block;
    margin-bottom: -2%;
    margin-top: -3%;
  }

  .ui-catalogo-top > div {
    text-align: left;
    margin-top: 0;
  }

  .carrinho-classe {
    min-width: 100% !important;
  }

  .pesquisa-classe {
    max-width: 100% !important;
    border: 0 !important;
  }

  .card-produto-classe .ui-el-item-destaque .ui-el-item-inner {
    top: 50px !important;
  }

  .ui-catalogo-content .carousel-slider {
    height: auto;
  }

  .ui-catalogo-carousel {
    height: 445px;
  }

  .ui-chart-top > div {
    display: block;
  }
  .ui-chart-top .ui-chart-actions button {
    display: block;
    width: calc(100% - 15px);
    margin-bottom: 5px;
  }
  .ui-el-item-comandos .ui-el-item-inner .ui-col button * {
    font-size: 10px !important;
  }
  .ui-card-produto .ui-el-item-comandos {
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 5px;
  }
  .ui-chart-header * {
    text-align: left;
  }
  .ui-chart-header .ui-col {
    border-bottom: 1px solid;
  }
  .ui-catalogo-top .ui-el-type-complete {
    width: 100%;
  }
  .ui-chart-totais .ui-grid-row {
    display: table-row;
  }
  .ui-chart-totais .ui-grid-col {
    display: table-cell;
  }
  .ui-layout-tab-menu .ui-grid-row {
    display: block;
  }
  .ui-fase.ui-grid-row {
    display: block;
  }
  .ui-el-item-regras > .ui-el-item-inner {
    width: 100%;
  }
  .ui-el-item-inner {
    float: inherit !important;
    width: 100%;
  }
  .ui-el-item-buttons .ui-el-item {
    width: 96%;
    margin: 0px 2%;
  }
  .MuiButton-root {
    width: 100%;
    margin-top: 2% !important;
    margin-bottom: 2% !important;
  }
  .ui-consultor .ui-el-item-tabs .ui-grid-col {
    background: #fff;
  }
  .ui-grid {
    display: block;
  }

  .ui-topo > .ui-col {
    line-height: 5px;
  }

  .ui-col.ui-pgto-valor.ui-padding.ui-click {
    width: 0;
  }

  .ui-dialog {
    max-width: calc(100% - 50px);
  }

  #menu-mobile-id {
    display: none;
  }

  #closeMenu {
    display: none;
  }

  .menu-mobile-dropdown {
    width: 100%;
    padding-left: 5px;
    font-size: 16px;
  }

  .top-cabecalho {
    box-shadow: 0px 2px 15px -12px rgb(0 0 0 / 75%);
    background-color: white;
    position: fixed;
    top: 65px;
    z-index: 99;
    width: 100%;
    margin-top: -2%;
  }

  .ui-catalogo-search .ui-el-item-comandos > div,
  .ui-catalogo-search > div > div {
    display: inline-flex;
    width: 100%;
  }

  .carrinho-classe div,
  .logo-classe div {
    display: flex;
    justify-content: center;
    padding-right: 10px;
    padding-top: 2px;
  }

  .product-detail .ui-current-grade-item .ui-el-item-infosp {
    background: var(--redBtnCart);
    margin: -10px;
    padding: 10px;
    border-radius: 5px;
    border-color: var(--redBtnCart);
    color: #fff;
  }

  .product-detail .ui-el-item-infosp {
    margin: -10px;
    padding: 10px;
    border-radius: 5px;
    border-color: var(--redBtnCart);
    box-shadow: 2px 2px 2px 2px #0000001f;
    color: rgb(0, 0, 0);
  }

  .ui-current-grade-item,
  .ui-el-type-list.ui-layout-button .ui-grid-row {
    background-color: #ffffff;
    border-color: var(--redBtnCart);
    color: #fff;
  }

  .zoomMaster {
    width: 0px;
  }

  .ui-resumo-pedido-label {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .ui-catalogo-item {
    display: block;
  }

  .ui-catalogo-item > .ui-catalogo-label {
    text-align: center;
  }

  .ui-catalogo-item-0 > .ui-catalogo-itens {
    width: 100%;
    position: absolute;
  }

  .ui-catalogo-item-0 > .ui-catalogo-itens > .ui-catalogo-sub {
    width: 100%;
  }

  .ui-catalogo {
    display: flex;
    padding-top: 35%;
  }

  .carousel .slide {
    min-width: 100%;
    margin: 0;
    position: unset;
    text-align: center;
    margin-top: 5%;
  }

  .estilo-sem-borda {
    border-bottom: 0 !important;
  }

  .ui-entrevistas div div div div div div {
    border-bottom: 0 !important;
  }

  .ui-el-item-logar {
    padding-bottom: 10%;
    margin-top: 30%;
  }

  .ui-login-panel * {
    color: #f5f5f5 !important;
    border-color: currentColor !important;
  }

  .b24-widget-button-wrapper.b24-widget-button-position-bottom-right.b24-widget-button-visible {
    bottom: 30px;
  }

  .mobile-Label-m {
    display: block;
    margin: 10px 0 0px 0;
  }

  .ui-filter-item {
    width: 100%;
  }

  div#remove {
    color: white;
    background-color: #eb5156;
    padding: 5px;
    width: 70px;
    margin: 10px 0px;
  }

  .anotacao-pagamento {
    padding-left: 0px;
  }

  .ui-bandeiras {
    display: inline-flex;
    flex-wrap: wrap;
  }

  .ui-fluxo-expansion {
    display: none !important;
  }

  .ui-col-12 {
    width: 100% !important;
  }

  .qtd-classe {
    display: inline-flex;
    border-radius: 30px;
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.add-spinn-classe-e {
    border-radius: 50px 0 0 50px !important;
    left: -21px;
    height: 49px !important;
    margin: -1px 0px -1px 10px !important;
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.add-spinn-classe-d {
    border-radius: 0 50px 50px 0 !important;
    right: -11px;
    height: 49px !important;
    margin: -1px 0px -1px 10px !important;
  }

  .label-qtd {
    margin: auto -30px;
    width: 80px;
  }

  .ui-promo-centro .ui-card {
    display: grid;
  }

  .ui-promo-select {
    width: 100%;
  }

  .ui-promo-centro .ui-promo-avatar {
    border: 0;
    width: 150px;
    padding: 15px !important;
    margin-top: 0;
  }

  .ui-promo-centro .ui-card {
    height: auto;
  }

  .ui-promo-detail {
    border-bottom: 1px solid #ababab;
  }

  .ui-fix-bottom {
    position: inherit;
  }

  .ui-promo-description {
    margin-left: 15px;
  }

  .ui-fix-bottom {
    position: inherit !important;
  }

  .ui-promo-resume .ui-card,
  .ui-promo-data {
    height: auto;
  }

  .ui-el-gallery .ui-dialog-data {
    display: flex;
  }

  .ui-el-gallery .ui-dialog-data img {
    height: auto;
  }

  .thumbs-wrapper.axis-vertical {
    display: none;
  }

  #ch5 div div div div div div div {
    display: flex;
    align-items: center;
  }

  #ch5 div div div div div div div div .ui-el-item {
    width: 100%;
  }

  .ui-col-empty {
    text-align: center;
    width: 100%;
    display: block;
  }

  div#data_inicial {
    text-align: left;
  }

  .ui-evt-passado.ui-grid-row.ui-row-even.ui-click {
    padding: 20px;
    border-bottom: 1px solid #d0d0d0;
  }

  .ui-evt-passado.ui-grid-row.ui-row-odd.ui-click {
    padding: 20px;
    border-bottom: 1px solid #d0d0d0;
  }

  .b24-widget-button-popup.b24-widget-button-popup-show {
    display: none !important;
  }

  .ui-el-item-inner img {
    width: 100%;
    max-width: none;
    max-height: none;
  }

  .ui-dash-eventos .ui-el-item-conteudo {
    height: 200px;
  }

  div#foto-not {
    width: 100% !important;
  }

  #ch3 tspan {
    font-size: 11px;
  }

  #seleciona_pagamento .MuiInputBase-root {
    display: inline-grid;
  }

  #seleciona_pagamento .MuiSelect-selectMenu {
    width: 90%;
  }

  .ui-item-noticias.ui-noticias-main.ui-grid-row.ui-row-even.ui-row-0 {
    display: block !important;
  }

  .ui-search-groups .ui-search-data > div {
    display: block;
  }

  .ui-search-groups .ui-search-data > div > .ui-grid > .ui-border-green {
    padding: 25px;
  }

  .ui-view-cliente-consultor .ui-fase .ui-grid-col {
    width: 99% !important;
  }

  .ui-view-carrinho .ui-content .ui-shadow {
    border-radius: 5px;
    width: 100%;
    border: 1px solid #cccccc;
    display: grid;
  }

  .ui-el-type-avatar {
    text-align: center;
  }

  .ui-el-avatar {
    text-align: center;
    width: auto !important;
  }

  .ui-el-item-buttons .ui-el-item .ui-el-item-inner {
    width: 100% !important;
  }

  .ui-el-item-destaque .ui-el-item-inner {
    width: auto;
  }

  .ui-catalogo-carousel .ui-layout-carousel {
    margin-top: 40px;
  }

  .classe-preco-original-sup .ui-el-item-inner {
    margin: 35px 0 -35px;
  }
}

@media (max-width: 650px) {
  .ui-catalogo-carousel .ui-layout-carousel .ui-grid-row.ui-card-produto {
    width: 100% !important;
  }
}

@media (min-width: 990px) {
  #seleciona_pagamento .MuiFormControl-root {
    width: 95%;
    margin-right: -200px;
  }

  .cod-mund {
    width: 15%;
  }

  .client-input {
    width: 30%;
  }

  .client-ped {
    width: 15%;
  }
}

@media (max-width: 1140px) {
  #seleciona_pagamento .MuiFormControl-root {
    width: 95%;
    margin-right: -280px;
  }
}
